import logo from './logo.svg';
import './App.css';
import styled, { css } from "styled-components";
import AleSwapLanding from './components/AleSwapLanding'


function App() {
  return (
    
    <div className="App">
      
        <AleSwapLanding/>
    </div>
  );
}

export default App;





