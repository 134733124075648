import React, { Component } from "react";
import { Button } from '@material-ui/core';
import {Link} from 'react-scroll'


import styled, { css } from "styled-components"
import CraftCoinImg from '../../assets/images/CraftCoinHead.png'
import uImage from '../../assets/images/land-bg.png'
import ExternalLink from '../../assets/images/ExternalLink.png'
import BlueBox from '../../assets/images/bluebox-bg.png'
import LaunchAppButtonTop from '../../assets/images/LaunchAppButton.png'
import DocsButton from '../../assets/images/DocsButton.png'
import TopLogoImg from '../../assets/images/TopLogoImg.png'
import LandingRedeemImg from '../../assets/images/LandingRedeemImg.png'
import TwitterLogo from '../../assets/images/twitter398.png'
import TelegramLogo from '../../assets/images/telegram398.png'
import GitBookLogo from '../../assets/images/gitbook398.png'
import GitHubLogo from '../../assets/images/github398.png'
import FaceBookLogo from '../../assets/images/fb398.png'
import MediumLogo from '../../assets/images/medium398.png'
import MitrCoverImg from '../../assets/images/MitrCover.png'
import DevanomCoverImg from '../../assets/images/DevanomCover.png'
import GroupBCoverImg from '../../assets/images/GroupBCover.png'
import CodeCraftCoverImg from '../../assets/images/CodeCraftCover.png'
import AkkeeCoverImg from '../../assets/images/AkkeeCover.png'
import YodBarCoverImg from '../../assets/images/YodbarCover.png'
import BeervanaCoverImg from '../../assets/images/BeervanaCover.png'
import ChitbeerCoverImg from '../../assets/images/ChitbeerCover.png'
import HausCoverImg from '../../assets/images/HausCover.png'
import NammonCoverImg from '../../assets/images/NammonCover.png'
import BluDoveCoverImg from '../../assets/images/BluDoveCover.png'
import NinetyCoverImg from '../../assets/images/NinetyCover.png'




import MitrLogoImg from '../../assets/images/MitrLogoImg.png'
import DevanomLogoImg from '../../assets/images/DevanomLogoImg.png'
import GroupBLogoImg from '../../assets/images/GroupBLogoImg.png'
import CodeCraftLogoImg from '../../assets/images/CodeCraftLogoImg.png'
import AkkeeLogoImg from '../../assets/images/AkkeeLogoImg.png'
import YodBarLogoImg from '../../assets/images/YodBarLogoImg.png'
import BeervanaLogoImg from '../../assets/images/BeervanaLogoImg.png'
import ChitbeerLogoImg from '../../assets/images/ChitbeerLogoImg.png'
import HausLogoImg from '../../assets/images/HausLogoImg.png'
import NammonLogoImg from '../../assets/images/NammonLogoImg.png'
import BluDoveLogoImg from '../../assets/images/BluDoveLogoImg.png'
import NinetyLogoImg from '../../assets/images/NinetyLogoImg.png'



import useSWR from 'swr'


export const ButtonTop = styled(Button)`

color: white;

font-weight:bold;


`;


export function useALEPrice() {
  const { data, error } = useSWR('https://api.aleswap.finance/ale/price')

  if (!data) return undefined
  return data.price
}

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`


function AleSwapLanding (props) {
  const alePrice = useALEPrice();

  return (
    
    <LandingContainer {...props}>



      <LandingScreen>
      <TopฺBarRow><TopฺBarLogo><TopฺBarLogoImg src={TopLogoImg}/></TopฺBarLogo><TopฺBarMenu><a href="https://app.aleswap.finance/#/swap?outputCurrency=0x99ca242f20424a6565cc17a409e557fa95e25bd7" target="_blank"><ButtonBuy>Buy ALE</ButtonBuy></a><a href="https://app.aleswap.finance/" target="_blank"><ButtonApp>LAUNCH APP</ButtonApp></a></TopฺBarMenu></TopฺBarRow>

        {/* <TopฺBarRow><TopฺBarLogo><TopฺBarLogoImg src={TopLogoImg}/></TopฺBarLogo><TopฺBarMenu><a href="https://aleswap.finance"><LaunchAppButtonTopOrange src={DocsButton}/></a> <a href="https://aleswap.finance"><LaunchAppButtonTopOrange src={LaunchAppButtonTop}/></a></TopฺBarMenu></TopฺBarRow> */}
        <LandingRow><CenterTextBox>Farm to Table. Deposit your cryptocurrency and yield Food, <br/>Beverages and more with our auto harvest algorithm.</CenterTextBox></LandingRow>
        
      
      
      <LandingRow>
        <CardContainer>
        <WhiteCard><OrangeBoldText>${!alePrice ? <Dots>Loading</Dots> : alePrice}</OrangeBoldText>ALE Price</WhiteCard>
        <WhiteCard><OrangeBoldText>$1.87M</OrangeBoldText>Total Value Locked</WhiteCard>
        
        <WhiteCard><OrangeBoldText>13</OrangeBoldText>Total Pools</WhiteCard>
        </CardContainer>
      </LandingRow>

      <PartnerRow>
      <BlueCenterTextBox>Partners</BlueCenterTextBox>
      <WhiteText>You can redeem Every crypto coin to Food & Beverage at these places.</WhiteText>
        <PartnerContainer>
        <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={MitrLogoImg}></PartnerLogoImg>
            <BoldText>Mitr Brewery</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={MitrCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={DevanomLogoImg}></PartnerLogoImg>
            <BoldText>Devanom</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={DevanomCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={GroupBLogoImg}></PartnerLogoImg>
            <BoldText>Group B</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={GroupBCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={CodeCraftLogoImg}></PartnerLogoImg>
            <BoldText>Code Craft</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={CodeCraftCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>15% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Bangkok</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          
          
        </PartnerContainer>
        <PartnerContainer>
        <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={AkkeeLogoImg}></PartnerLogoImg>
            <BoldText>Akkee</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={AkkeeCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={YodBarLogoImg}></PartnerLogoImg>
            <BoldText>Yod Bar</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={YodBarCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={BeervanaLogoImg}></PartnerLogoImg>
            <BoldText>Beervana</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={BeervanaCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Beer Importer</DetailLeftText>
              <DetailRightText>Bangkok</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={ChitbeerLogoImg}></PartnerLogoImg>
            <BoldText>Chit Beer</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={ChitbeerCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          
          
        </PartnerContainer>
        <PartnerContainer>
        <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={HausLogoImg}></PartnerLogoImg>
            <BoldText>HAUS</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={HausCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Food&Beverages</PromoTextLeft></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Shabu Shabu</DetailLeftText>
              <DetailRightText>Bangkok</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={NammonLogoImg}></PartnerLogoImg>
            <BoldText>Nammon</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={NammonCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Tonic Mixer</DetailLeftText>
              <DetailRightText>Nonthaburi</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={BluDoveLogoImg}></PartnerLogoImg>
            <BoldText>Blu Dove</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={BluDoveCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Craft Beer Bar</DetailLeftText>
              <DetailRightText>Bangkok</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          <PartnerCard>
          <PartnerCardRow>
            <PartnerLogoImg src={NinetyLogoImg}></PartnerLogoImg>
            <BoldText>90 Ninety Coffee</BoldText>
          </PartnerCardRow>
          <PartnerCardRow><PartnerImg src={NinetyCoverImg}></PartnerImg></PartnerCardRow>
          <PartnerCardRow><PromoTextRow><PromoTextLeft>Beverages</PromoTextLeft><PromoTextRight><OrangeText>10% OFF</OrangeText></PromoTextRight></PromoTextRow></PartnerCardRow>
          <PartnerCardRow>
            <DetailTextRow>
              <DetailLeftText>Coffee Beans</DetailLeftText>
              <DetailRightText>Bangkok</DetailRightText>
              </DetailTextRow>
          </PartnerCardRow>
          </PartnerCard>
          
          
        </PartnerContainer>
        
      </PartnerRow>
      
      <LandingRow>
      <BlueCenterTextBox>Our Community</BlueCenterTextBox>
        <CardContainer>
        <a href="https://twitter.com/aleswapfinance"><SocialCard><SocialIcon src={TwitterLogo}></SocialIcon>TWITTER</SocialCard></a>
        <a href="https://t.me/aleswap"><SocialCard><SocialIcon src={TelegramLogo}></SocialIcon>TELEGRAM THAI</SocialCard></a>
        <a href="https://t.me/aleswap_e"><SocialCard><SocialIcon src={TelegramLogo}></SocialIcon>TELEGRAM INTER</SocialCard></a>
        <a href="https://www.facebook.com/AleSwap/"><SocialCard><SocialIcon src={FaceBookLogo}></SocialIcon>FACEBOOK</SocialCard></a>
        
        
        
        
        </CardContainer>
        <CardContainer>
        <a href="https://aleswap.medium.com/ "><SocialCard><SocialIcon src={MediumLogo}></SocialIcon>MEDIUM</SocialCard></a>
        <a href="https://github.com/aleswap-finance/"><SocialCard><SocialIcon src={GitHubLogo}></SocialIcon>GITHUB</SocialCard></a>
        <a href="https://aleswap-finance.gitbook.io/aleswap/ "><SocialCard><SocialIcon src={GitBookLogo}></SocialIcon>GITBOOK</SocialCard></a>
        

        
        </CardContainer>

        
        
      </LandingRow>
      <LandingRow>
      <FooterBox></FooterBox>
        
      </LandingRow>
      
      
       
      </LandingScreen>
      
    </LandingContainer>

    
  );
}





const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color:#347AF0;
  
  
 
  
  
  
`;

const TopฺBarRow = styled.div`
height:100px;
width: 80%;


font-weight:bold;
text-decoration: none;  
margin-bottom:80px;
@media (max-width: 360px) {
  margin-bottom:60px;
}

@media (max-width: 414px) {
  margin-bottom:60px;
  
}


`;

const TopฺBarLogo = styled.div`
height:100px;
float: left;
width:50%;
@media (max-width: 360px) {
  width: 100%;
}

@media (max-width: 414px) {
  width: 100%;
  margin-left:50px;
  
}

`;

const TopฺBarLogoImg = styled.img`
margin-top:20px;
width: 100%;
max-width: 300px;

display:grid;
align-items: center;
justify-items: center;

@media (max-width: 600px) {
  width: 70%;
}


`;


const TopฺBarMenu = styled.div`
height:100px;
float: right;
justify-content: flex-end;
display:flex;
margin-top:40px;
width:50%;
text-decoration: none;  
@media (max-width: 360px) {
  width: 100%;
  float: none;
}

@media (max-width: 414px) {
  width: 100%;
  float: none;
  justify-content: center;
  
}
`;

const LaunchAppButtonTopOrange = styled.img`
width: 30%;
margin-top:30px;
margin-left:10px;
float: right;
@media (max-width: 360px) {
  width: 40%;
  margin-top:0px;
margin-left:5px;
}

`;

const ButtonApp = styled.button`
color:#eabb4e;
background-color:#ffffff;
font-weight:900;
font-size:20px;
border-radius: 30px;
border:0px;
height:40px;
font-family:'Titillium Web';
padding-left:20px;
padding-right:20px;

@media (max-width: 360px) {
  
  
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;
  
}



&:hover {
  
  box-shadow: 0 0 0 3pt #195BCB;
  cursor: pointer;
}

`
;

const ButtonBuy = styled.button`
background-color:#eabb4e;
color:#ffffff;
font-weight:900;
font-size:20px;
border-radius: 30px;
border:0px;
height:40px;
font-family:'Titillium Web';
padding-left:20px;
padding-right:20px;
margin-right:10px;
&:hover {
  
  box-shadow: 0 0 0 3pt #195BCB;
  cursor: pointer;
}

@media (max-width: 414px) {
  font-size:16px;
  
min-width:120px;
  
}
`
;






const TopLaunchBotton = styled.span`
position: absolute; 
top: 30px; 
right: 50px; 


  width: 160px;
  

  
  font-weight:bold ;

color:#ffffff; !important
text-decoration: none;
  
`;

// const TopLaunchBotton = styled.span`
// position: absolute; 
// top: 30px; 
// right: 50px; 


//   width: 120px;
//   background-color:#F3BA2F;

//   border-radius: 38px;
//   font-weight:bold ;

// color:#ffffff; !important
// text-decoration: none;
  
// `;

const TopLaunchText = styled.span`

  
`;




const LandingRow = styled.div`
display:grid;
  width: 100%;

  
  
  align-items: center;
justify-items: center;

margin-bottom:60px;

@media (max-width: 360px) {
  margin-bottom:20px;
}

@media (max-width: 414px) {
  margin-bottom:0px;
}


`;



const PartnerRow = styled.div`
display:grid;
  width: 100%;

  background: rgba(255, 255, 255, 0.3) ;
  
  align-items: center;
justify-items: center;

padding-bottom:60px;
margin-top:120px;
@media (max-width: 360px) {
  padding-bottom:20px;
  margin-top:60px;
}




`;

const LandingTopMenu = styled.div`
display:flex;
justify-content:flex-end;
  width: 100%;
  
  

`;

const LandingLogo = styled.img`
margin-top:50px;
width: 50%;
max-width: 490px;

display:grid;
align-items: center;
justify-items: center;

@media (max-width: 600px) {
  width: 70%;
}


`;



const LandingRedeem = styled.img`
margin-top:50px;
width: 70%;
max-width: 700px;

display:grid;
align-items: center;
justify-items: center;

@media (max-width: 600px) {
  width: 70%;
}


`;

const CenterTextBox = styled.div`
display:grid;
  width: 100%;
  
  align-items: center;
justify-items: center;
text-align:center;

font-weight:bold;
font-size:2.8em;
color:#ffffff;

@media (max-width: 600px) {
  font-size:1.2em;
  margin-top:30px;
  width: 80%;
}

margin-top:50px;
margin-bottom:30px;

@media (max-width: 360px) {
  
 
  margin-bottom:0px;
  
}

`;

const BlueCenterTextBox = styled.div`
display:grid;
  width: 100%;
  
  align-items: center;
justify-items: center;
text-align:center;

font-weight:bold;
font-size:1.5em;
color:#002C70;

@media (max-width: 600px) {
  font-size:1.2em;
  margin-top:30px;
  width: 80%;
}

margin-top:30px;

`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width:920px;
 

  margin-top:30px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
justify-items: center;
display: grid;
  }
  

  @media (max-width: 360px) {
    flex-direction: column;
    align-items: center;
    justify-items: center;
    
      align-self:center;
      
    }
   
display: flex;
margin-top:0px;

  }
`;

const PartnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width:1100px;
 

  margin-top:30px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
justify-items: center;
display: grid;
  }
  

  @media (max-width: 360px) {
    flex-direction: column;
    align-items: center;
justify-items: center;
display: grid;
margin-top:0px;
  }
`;

const CardContainerCenter = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width:920px;
 

  margin-top:30px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
justify-items: center;
display: grid;
  }
  
`;

const WhiteCard = styled.div`
  display: grid;
  
  width: 33%;
  min-height:100px;

  @media (max-width: 600px) {
    flex-direction: coloumn;
    width: 50%;
    margin-top:20px;
  }

  background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
align-items: center;
justify-items: center;
margin-left:10px;
margin-right:10px;
  
`;

const PartnerCard = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 25%;
  min-height:100px;

  @media (max-width: 600px) {
    
    width: 50%;
    margin-top:20px;
  }

  @media (max-width: 360px) {
    
    width: 70%;
    margin-top:20px;
  }

  @media (max-width: 414px) {
    width: 70%;
    margin-top:20px;
    
  }

  background-color:#ffffff;
  max-width:300px;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 5px;

justify-items: center;
margin-left:10px;
margin-right:10px;
padding-bottom:10px;
  
`;

const PartnerCardRow = styled.div`
display:flex;
width:100%  
align-items:center;


`;

const PartnerCardSpace = styled.div`

width:100%  
display: flex;
justify-content: space-between;

`;


const PartnerLogoImg = styled.img`

width: 48px;
padding-left:8px;
padding-top:8px;
padding-bottom:8px;
padding-right:8px;

`;

const PartnerNameText = styled.div`
font-weight:bold;

padding-left:10px;


`;

const PartnerImg = styled.img`

width: 100%;

`;

// const PromoTextRow = styled.div`
// font-weight:700;
// padding-left:10px;
// padding-top:10px;
// `;

const PromoTextRow = styled.div`

width: 100%;
padding-top:10px;

font-weight:bold;



`;

const PromoTextLeft = styled.div`

float: left;
display:flex;
justify-content: flex-start;
width:60%;
margin-left:10px;
@media (max-width: 360px) {
  width: 60%;
}

`;




const PromoTextRight = styled.div`

float: right;
justify-content: flex-end;
display:flex;

padding-right:10px;

width:30%;
text-decoration: none;  
@media (max-width: 360px) {
  width:30%;
  float: none;
}
`;

const DetailTextRow = styled.div`

width: 100%;


color:#979797;



`;

const DetailLeftText = styled.div`

float: left;
display:flex;
justify-content: flex-start;
width:60%;
margin-left:10px;
@media (max-width: 360px) {
  width: 60%;
}

`;




const DetailRightText = styled.div`

float: right;
justify-content: flex-end;
display:flex;

padding-right:10px;

width:30%;
text-decoration: none;  
@media (max-width: 360px) {
  width:30%;
  float: none;
}
`;

// const DetailTextRow = styled.div`
// width:100%;
// color:#979797;

// display: grid;
// grid-template-columns:1fr 1fr;
// grid-template-areas:
// 'lefttext righttext';
// `;

// const DetailLeftText = styled.div`
// color:#979797;
// grid-area:lefttext;
// margin-left:-12px;

// `;

// const DetailRightText = styled.div`
// color:#979797;
// grid-area:righttext;
// text-align:right;
// padding-right:10px;


// `;


const SocialCard = styled.div`
  display: grid;
  
  width: 200px;
  min-height:120px;

  @media (max-width: 600px) {
    flex-direction: coloumn;
    width: 50%;
    margin-top:20px;
    margin-left:70px;
  }

  @media (max-width: 360px) {
    flex-direction: coloumn;
    width: 80%;
    margin-top:20px;
    
    margin-left:30px;
    
  }

  background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
align-items: center;
justify-items: center;
margin-left:10px;
margin-right:10px;
font-weight:bold;
padding-top:20px;
padding-bottom:20px;
text-decoration: none;
margin-top:20px;
  
`;


const OrangeBoldText = styled.div`
font-weight:bold;
font-size:36px;
color:#F3BA2F;
max-height:20px;
`;

const OrangeText = styled.span`

color:#F3BA2F;
text-align:right;

`;

const WhiteText = styled.span`

color:#FFFFFF;

`;

const SocialIcon = styled.img`
  
  width:40%;
  margin-bottom:10px;

  @media (max-width: 360px) {
    
    
  }
  
  
`;


const WhiteBox = styled.div`
@media (max-width: 992px) {
  width: 520px;
}

@media (max-width: 600px) {
  width: 400px;
  margin-top:50px;
}
width:520px;
max-width:520px;
background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;
display:grid;
align-items: center;
justify-items: center;
margin-bottom:20px;
background: url(${uImage});
padding-top:10px;
padding-bottom:10px;

  font-size: 1.5vw;
  text-align:center;


`;
const WhiteBoxText = styled.div`

@media (max-width: 992px) {
  width: 520px;
}

@media (max-width: 600px) {
  width: 400px;
  font-size: 3vw;
}
width:520px;
max-width:520px;
background-color:#ffffff;
color:#0B2553;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;
background: url(${uImage}); 
padding-top:10px;
padding-bottom:10px;

  
  text-align:center;


`;

const BoldText =styled.span`

font-weight:bold;

align-self:center;
font-size:20px;
  
  
 



`;

const CraftCoinHead = styled.img`

width: 60%;

display:grid;
align-items: center;
justify-items: center;


`;

const ExternalImg = styled.img`

width: 18px;
display: inline-block;



`;

const CraftRow = styled.div`
@media (max-width: 992px) {
  width: 520px;
}

@media (max-width: 600px) {
  width: 400px;
  font-size: 1.5vw;
}
width:520px;
max-width:520px;
display: grid;
    grid-template-columns: 1fr 1fr;
    
    column-gap:20px;
    row-gap: 20px;


`;

const CraftItem = styled.div`


padding: 10px;

padding-top: 20px;




background-color:#347AF0;
color:#FFFFFF;
box-shadow: 0px 1px 1px #00000040;
border-radius: 15px;

align-items: center;
justify-items: center;
margin-bottom:20px;

background: url(${BlueBox}); 
display:grid;
font-weight:bold;
font-size:1.8vw;


`;

const CraftItemImg = styled.img`
margin-left:10%;
margin-bottom:20px;
margin-top:10px;

width: 80%;




`;

const FooterBox = styled.img`
height:100px;




`;




const LandingScreen = styled.div`
  width: 100%;
  height: 100%;
  padding-top:10px;

  
  
  flex-direction: column;
  

 
  display: flex;
  align-items: center;
  
   margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  
  
`;





export default AleSwapLanding;
